<script setup lang="ts">
import type { PropType } from "vue";
import Text from "~/components/text/text.vue";
import type { IAdvantageValue } from "~/composable/advantage.type";
import type { ISwiperBreakpoints } from "~/composable/swiper.type";
defineProps({
  items: {
    type: Object as PropType<IAdvantageValue[]>,
    required: true,
  },
  title: {
    type: String,
    required: true,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
});
const breakpoints = ref<ISwiperBreakpoints>({
  0: {
    slidesPerView: 1,
    spaceBetween: 12,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 12,
  },
  960: {
    slidesPerView: 2,
    spaceBetween: 12,
  },

  1280: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
});
</script>

<template>
  <div class="eqzaim-advantages">
    <Slider
      :show-navs="true"
      :breakpoints="breakpoints"
      class="eqzaim-slide__wrapper"
    >
      <template #title>
        <h2 class="eqzaim-advantages__title">{{ title }}</h2>
      </template>
      <template #default>
        <swiper-slide
          lazy="true"
          v-for="(slide, index) in items"
          :key="index"
          class="h-auto"
        >
          <div
            class="eqzaim-slide"
            :class="[`eqzaim-slide-${slide.options.color}`]"
          >
            <div class="eqzaim-slide__content">
              <div v-show="slide.title !== ''" class="eqzaim-slide__title">
                {{ slide.title }}
              </div>
              <div class="eqzaim-slide__text">
                <Text
                  title=""
                  description=""
                  v-if="Object.keys(slide.mainText).length > 0"
                  :items="{ code: slide.title, text: slide.mainText }"
                />
              </div>
            </div>
            <img
              :src="slide.image"
              :alt="slide.title"
              v-if="slide.image"
              loading="lazy"
              class="eqzaim-slide__image"
            />
            <div class="eqzaim-slide__link">
              <NuxtLink :to="slide.value">{{
                slide.options.textLink
              }}</NuxtLink>
            </div>
          </div>
        </swiper-slide>
      </template>
    </Slider>
  </div>
</template>

<style scoped lang="postcss">
.eqzaim-advantages {
  @apply container m-auto overflow-hidden;
  &__title {
    @apply xl:text-heading-L md:text-heading-XS text-heading-XXXS text-primary-black;
  }
}

.eqzaim-slide {
  @apply p-content rounded-slider text-primary-black pb-[150px] sm:pb-[190px] md:pb-[220px] lg:pb-content flex flex-wrap h-full;

  &__wrapper {
    @apply lg:overflow-hidden;
  }

  &-blue {
    @apply bg-secondary-block-blue;

    .eqzaim-slide__link {
      a {
        @apply bg-secondary-block-blue text-secondary-blue-green;
      }
    }
  }

  &-yellow {
    @apply bg-secondary-block-yellow;

    .eqzaim-slide__link {
      a {
        @apply bg-secondary-orange text-white;
      }
    }
  }

  &-gray {
    @apply bg-secondary-gray-50-50;

    .eqzaim-slide__link {
      a {
        @apply bg-secondary-brown-25 text-primary-black;
      }
    }
  }

  &__title {
    @apply md:text-title-L text-title-XS mb-16px line-clamp-2;
  }
  &__content {
    @apply lg:mb-96px;
  }
  &__text {
    @apply md:text-normal-S-regular text-normal-XS;
  }

  &__link {
    @apply absolute left-[32px]
    bottom-[96px] sm:bottom-[130px] md:bottom-[150px] lg:bottom-[32px];

    a {
      @apply text-center rounded-slider md:text-title-XS text-title-XXS md:py-14px py-12px px-32px inline-block;
    }
  }

  &__image {
    @apply absolute bottom-0 lg:right-0 xl:h-auto lg:h-[160px] md:h-[145px] sm:h-[125px] h-[90px] right-1/2 translate-x-1/2 lg:translate-x-0;
  }
}
</style>
